import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Range, getTrackBackground } from "react-range";
import axiosConfig from '../../axiosConfig';
import { Modal } from "react-bootstrap";
import { useLoadScript, Autocomplete, MarkerF, GoogleMap, Circle } from '@react-google-maps/api';

import { useFilterSearch } from '../../context/FilterSearchContext';
import { alignContent, zIndex } from "styled-system";

const STEP = 1;
const MIN = 50;
const MAX = 180;

const CheckStyled = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #2b3940 !important;
  font-size: 16px;
  color: inherit;
  &::before {
    content: "\f0c8";
    font-weight: 400;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    color: #7e8989;
    margin-right: 11px;
    margin-top: 2px;
  }
  &.active {
    color: #2b3940 !important;
    font-weight: 600;
    &::before {
      content: "\f14a";
      font-weight: 900;
      color: #00b074;
    }
  }
`;

const Check = ({ children, onClick, item, filterGroupKey }) => {
  const { selectedHrItems } = useFilterSearch();

  // Ensure selectedUserItems contains the right category and is an array
  const isActive =
    selectedHrItems[filterGroupKey] &&
    Array.isArray(selectedHrItems[filterGroupKey]) &&
    selectedHrItems[filterGroupKey].some(
      (selectedItem) => selectedItem.title === item.title
    );

  const handleClick = () => {
    onClick(); // Trigger the onClick passed from the parent
  };

  return (
    <CheckStyled
      className={`toggle-item ${isActive ? "active" : ""}`}
      onClick={handleClick}
    >
      {children}
    </CheckStyled>
  );
};

const ProfileFilterSidebar = () => {

  const {
    hrFilterData = {},
    hrFilterDataAll = {},
    handleHrFilterSave = () => { },
    handleHrFilterClear = () => { },
    selectedHrItems = {},
    setSelectedHrItems = () => { },
  } = useFilterSearch() || {};

  const camelCaseToTitle = (camelCaseString) => {
    return camelCaseString
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleSelectHrItem = (category, value) => {
    const currentSelectedItems = selectedHrItems[category] || [];

    if (currentSelectedItems.some(item => item.id === value.id)) {
      setSelectedHrItems({
        ...selectedHrItems,
        [category]: currentSelectedItems.filter(item => item.id !== value.id)
      });
    } else {
      setSelectedHrItems({
        ...selectedHrItems,
        [category]: [...currentSelectedItems, value]
      });
    }
  };

  useEffect(() => {
    console.log('These are the selected hr items:', selectedHrItems);
  }, [selectedHrItems])

  const [rangeValues, setRangeValues] = useState([70, 150]);

  const styles = ProfileFilterSidebarStyling();


  return (
    <>
      {/* <!-- Sidebar Start --> */}

      <div className="scrollable-container" style={{ height: '400px', overflowY: 'auto' }}>

        {Object.keys(hrFilterDataAll).map((filterGroupKey, filterGroupIndex) => {
          const filterGroup = hrFilterDataAll[filterGroupKey];

          return (
            <div key={filterGroupIndex}>
              <div className="widgets mb-11">
                <h4 className="font-size-6 font-weight-semibold mb-6">
                  {camelCaseToTitle(filterGroupKey)}
                </h4>
                <ul className="list-unstyled filter-check-list">
                  {/* Check if filterGroup is an array and map over it */}
                  {Array.isArray(filterGroup) && filterGroup.length > 0 ? (
                    filterGroup.map((item, index) => (
                      <li key={index} className="mb-2">
                        <Check
                          onClick={() => handleSelectHrItem(filterGroupKey, item)}
                          item={item}
                          filterGroupKey={filterGroupKey} // Pass the filter category
                        >
                          {item.title}
                        </Check>
                      </li>
                    ))
                  ) : (
                    <li>No data available</li>
                  )}
                </ul>
              </div>
            </div>
          );
        })}
      </div>

      <div className="w-100 d-flex justify-content-start mt-2 mb-5">
        <button
          className="btn btn-green text-uppercase rounded-3 m-1"
          onClick={() => handleHrFilterSave()}
        >
          Apply Filter
        </button>
        <button
          className="btn btn-outline-mercury text-uppercase rounded-3  border border-danger m-1"
          onClick={() => handleHrFilterClear()}
        >
          <span className="text-danger">Clear Filter</span>
        </button>
      </div>

      {/* <!-- Sidebar End --> */}
    </>
  );
};

export default ProfileFilterSidebar;

function ProfileFilterSidebarStyling() {
  const styles = {

  };

  return styles;
}



